<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <!-- div v-show="!user_subscript">
    <div v-if="showSideAds">
        <div id="leftSideAds" class="gads_fixed" style="height: 500px; width: 150px; min-width: 100px; position: fixed; top: 150px; left: 20px; z-index: -1;">
	  <div style="width: 150px; height: 500px;">
            <ins class="adsbygoogle"
            style="display:block; width:150px; height:500px;"
            data-ad-client="ca-pub-5170371475668123"
            data-ad-slot="1896233389"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
	  </div>
        </div>
    </div>
  </div -->
  <div class="main" style="min-height: 600px">
      <div class="main_title" style="min-height: 100px;">
	<div>
          <H1 class="main_title_p01"><span>{{ $t('txt2voice.main_title_p01') }}</span></H1>
	</div>
	<div>
          <H2 class="main_title_p02"><span>{{ $t('txt2voice.main_title_p02') }}</span></H2>
	</div>
      </div>
      <div class="main_div">
          <div class="main_div_word">
              <div class="main_div_word_title">
                  <p class="active">{{ $t('comm.txt2voice') }}</p>
              </div>
              <div class="main_div_word_teatarea">
                  <textarea v-model="voicetext" id="audiodescribe" type="text"
                            v-bind:placeholder="$t('txt2voice.main_textarea_holder')"
                            onfocus="if(value==defaultValue){value='';this.style.color='#000'}"
                            onblur="if(!value){value=defaultValue; this.style.color='#c9caca'}"
                            v-on:input="wordCnt" maxlength="300"
                            onpropertychange="if(value.length>300) value=value.substr(0,300)"></textarea>
              </div>
              <div class="main_div_word_bottom">
                  <div class="main_div_word_bottom_left">
                      <img src="/ssr/img/play.png" alt="Tik Tok Voice Generator play" class="main_div_word_bottom_left_img"/>
                        <select v-model="selectLang" @change="changeLang" class="main_div_word_bottom_left_select" aria-label="tiktok voice generator select lang">
                           <option v-for="item in allLangs" v-bind:key="item.value" v-bind:value="item.value">
                               {{ item.label }}
                           </option>
                        </select>
                  </div>
                  <div class="main_div_word_bottom_left">
                      <img src="/ssr/img/play.png" alt="TikTok AI Voice Generator play" class="main_div_word_bottom_left_img"/>
                        <select v-model="selectModel" @change="changeModel" class="main_div_word_bottom_left_select2" aria-label="tiktok voice generator select model">
                           <option v-for="option in selectLangModels" v-bind:key="option.value" v-bind:value="option.value">
                               {{ option.label }}
                           </option>
                        </select>
                  </div>
                  <div class="main_div_word_bottom_right">
                      <span>{{ wordcnt }}/300</span>
                      <button ref='gen_button' class="main_div_bottom_right_button" v-on:click="genVoice" v-bind:disabled="disabled">
                        {{ $t('txt2voice.main_genvoice') }}
                      </button>
                  </div>
              </div>
          </div>
          <div class="main_div_audio">
              <div id="wrapper" class="main_div_audio_box">
                  <div class="audio_container">
                    <audio ref='audio_main' controls="controls" preload="auto" id="audio_tagert" class="main_div_audio_css">
                      <source :src="selectModelWavplay">
                    </audio>
                  </div>
		  <div class="main_div_audio_buttons">
                    <button v-on:click="playAudio" class="main_div_audio_button" aria-label="audio replay">
                      <img src="/ssr/img/reload.png" alt="Tik Tok Voice reload" width="24" height="24" />
                    </button>
                    <a ref='audio_down' :href="selectModelWavplay" id="audio_down_url" class="main_div_audio_button" aria-label="TikTok AI Voice audio download" target="_blank">
                        <img src="/ssr/img/upload.png" alt="TikTok text to speech upload" width="24"  height="24"/>
                    </a>
		  </div>
              </div>
          </div>
          <div v-if="!user_subscript">
	    <div id="ads-banner1" class="gads_fixed" style="height: 200px; overflow: hidden;">
              <!-- 横条固定位广告 -->
              <ins class="adsbygoogle"
                style="display:block; width:100%; height:200px;"
                data-ad-client="ca-pub-5170371475668123"
                data-ad-slot="3430211872"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            </div>
          </div>
          <div class="faq_section">
              <H2 class="main_div_quest">{{ $t('faq.quest1') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer1_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer1_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer1_3') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer1_4') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest2') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer2_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer2_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer2_3') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer2_4') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest3') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer3_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer3_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer3_3') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer3_4') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer3_5') }}</p>
              <H2 class="main_div_quest">{{ $t('faq.quest4') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer4_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer4_2') }}</p>
              <p> </p>
              <div v-if="!user_subscript">
	        <div id="ads-banner2" class="gads_fixed" style="height: 200px; overflow: hidden;">
                  <!-- 横条固定位广告 -->
                  <ins class="adsbygoogle"
                    style="display:block; width:100%; height:200px;"
                    data-ad-client="ca-pub-5170371475668123"
		    data-ad-slot="2419424672"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                </div>
              </div>
              <H2 class="main_div_quest">{{ $t('faq.quest5') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer5_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer5_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer5_3') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest6') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer6_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer6_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer6_3') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest7') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer7_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer7_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer7_3') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest8') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer8_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer8_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer8_3') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest9') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer9_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer9_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer9_3') }}</p>
              <p> </p>
              <div v-if="!user_subscript">
	        <div id="ads-banner2" class="gads_fixed" style="height: 200px; overflow: hidden;">
                  <!-- 横条固定位广告 -->
		  <ins class="adsbygoogle"
		       style="display:block; text-align:center;width:100%; height:200px;"
		       data-ad-layout="in-article"
		       data-ad-format="fluid"
		       data-ad-client="ca-pub-5170371475668123"
		       data-ad-slot="4726325139"></ins>
                </div>
              </div>
              <H2 class="main_div_quest">{{ $t('faq.quest10') }}</H2>
	      <p class="main_div_answer">{{ $t('faq.answer10_1') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_2') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_3') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_4') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_5') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_6') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_7') }}</p>
              <p class="main_div_answer">{{ $t('faq.answer10_8') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest11') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer11') }}</p>
              <p> </p>
              <H2 class="main_div_quest">{{ $t('faq.quest12') }}</H2>
              <p class="main_div_answer">{{ $t('faq.answer12') }}</p>
         </div>
      </div>
  </div>
  <!-- div v-show="!user_subscript">
    <div v-if="showSideAds">
        <div id="rightSideAds" class="gads_fixed" style="height: 500px; width: 150px; min-width: 100px; position: fixed; top: 150px; right: 20px; z-index: -1;">
	  <div style="width: 150px; height: 500px;">
            <ins class="adsbygoogle"
                 style="display:block; width:150px; height:500px;"
                 data-ad-client="ca-pub-5170371475668123"
                 data-ad-slot="6060200481"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            </div>
       </div>
    </div>
  </div -->
  <div id="popupContainer">
    <div v-for="(data, id) in popupData" :key="id" class="popup-modal" v-show="popupStates[id]">
      <div class="popup-content">
        <span class="popup-close" @click="closePopup(id)">&times;</span>
	<div class="login_logo">
                <img src="/ssr/img/tiktok_voice_logo.svg" alt="TikTok AI Voice Generator logo" loading="lazy" height="60px" width="80px" class="top_left_logo"/>
                <!-- img src="/ssr/img/tiktok_voice_word.svg" alt="TikTok AI Voice Generator logo" loading="lazy" height="60px" width="160px" class="top_left_logo"/ -->
            </div>
        <h2 class="popup-modal-title">{{ data.title }}</h2>
        <p class="popup-modal-desc">{{ data.description }}</p>
        <a href="/user/pricing" class="popup-btn">{{ data.buttonText }}</a>
      </div>
    </div>
  </div>
  <div class="footer" id="bloc-10">
    <div>
      <voice-footer></voice-footer>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { reactive } from 'vue'
import axios from 'axios'
import debounce from 'lodash/debounce';
import { addBannerAds, lazyLoadAds } from '../utils/googleAds.js';
import { setCookie, delCookie, getCookie } from '../utils/cookies';

// 异步加载组件
const VoiceHeader = defineAsyncComponent(() => import('../components/VoiceHeader.vue'))
const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooter.vue'))

const popup_nologin = "popup_nologin"
const popup_nosub = "popup_nosub" 
const popup_sublimit = "popup_sublimit"

export default {
  name: 'home',
  components: {
    VoiceHeader,
    VoiceFooter
  },
  data() {
    return {
      langsModels: null,
      allLangs: null,
      selectLang: null,
      selectLangModels: null,
      selectModel: null,
      selectModelWavplay: '',
      voicetext: '',
      disabled: false,
      wordcnt: 0,
      text_maxlen: 300,
      email: '',
      user_subscript: 0,  // 有4个取值, 0 未订阅, 1 已订阅, 2 虽订阅,字符已用完
      showSideAds: false,
      pricing_href: '/en/pricing',
      debouncedCheckShowSideAds: null,
      popupData: {
          // 未登录用户
          popup_nologin: {
	      title: this.$i18n.t('txt2voice.popup_nologin_title'),
	      description: this.$i18n.t('txt2voice.popup_nologin_desc'),
	      buttonText: this.$i18n.t('txt2voice.popup_nologin_btntext'),
          },
          // 登录用户,未订阅
          popup_nosub: {
	      title: this.$i18n.t('txt2voice.popup_nosub_title'),
	      description: this.$i18n.t('txt2voice.popup_nosub_desc'),
	      buttonText: this.$i18n.t('txt2voice.popup_nosub_btntext'),
          },
          // 登录用户, 已达到订阅套餐上限
          popup_sublimit: {
	      title: this.$i18n.t('txt2voice.popup_sublimit_title'),
	      description: this.$i18n.t('txt2voice.popup_sublimit_desc'),
	      buttonText: this.$i18n.t('txt2voice.popup_sublimit_btntext'),
          }
      },
      popupStates: reactive({}),
    }
  },
  head() {
    return {
      'title': this.$i18n.t('txt2voice.title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description'),
    }
  },
  metaInfo() {
    return {
      link: [
        { rel: 'alternate', hreflang: 'en', href: 'https://tiktokvoice.net/' },
        { rel: 'alternate', hreflang: 'ja', href: 'https://tiktokvoice.net/ja' },
        { rel: 'alternate', hreflang: 'zh', href: 'https://tiktokvoice.net/zh' },
        { rel: 'alternate', hreflang: 'zh-tw', href: 'https://tiktokvoice.net/zh-tw' },
        { rel: 'alternate', hreflang: 'ko', href: 'https://tiktokvoice.net/ko' },
        { rel: 'alternate', hreflang: 'vi', href: 'https://tiktokvoice.net/vi' },
        { rel: 'alternate', hreflang: 'th', href: 'https://tiktokvoice.net/th' },
        { rel: 'alternate', hreflang: 'hi', href: 'https://tiktokvoice.net/hi' },
        { rel: 'alternate', hreflang: 'fa', href: 'https://tiktokvoice.net/fa' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://tiktokvoice.net/ru' },
        { rel: 'alternate', hreflang: 'de', href: 'https://tiktokvoice.net/de' },
        { rel: 'alternate', hreflang: 'fr', href: 'https://tiktokvoice.net/fr' },
        { rel: 'alternate', hreflang: 'ro', href: 'https://tiktokvoice.net/ro' },
        { rel: 'alternate', hreflang: 'cs', href: 'https://tiktokvoice.net/cs' },
        { rel: 'alternate', hreflang: 'es', href: 'https://tiktokvoice.net/es' },
        { rel: 'alternate', hreflang: 'pt', href: 'https://tiktokvoice.net/pt' },
        { rel: 'alternate', hreflang: 'bn', href: 'https://tiktokvoice.net/bn' },
        { rel: 'alternate', hreflang: 'it', href: 'https://tiktokvoice.net/it' },
        { rel: 'alternate', hreflang: 'ar', href: 'https://tiktokvoice.net/ar' },
        { rel: 'alternate', hreflang: 'ur', href: 'https://tiktokvoice.net/ur' },
        { rel: 'alternate', hreflang: 'ms', href: 'https://tiktokvoice.net/ms' },
        { rel: 'alternate', hreflang: 'tr', href: 'https://tiktokvoice.net/tr' },
        { rel: 'alternate', hreflang: 'pl', href: 'https://tiktokvoice.net/pl' },
        { rel: 'alternate', hreflang: 'nl', href: 'https://tiktokvoice.net/nl' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://tiktokvoice.net/uk' },
      ]
    }
  },
  asyncData: function ({ store, route }) {
    // console.log('home state count:' + store.state.count)
    return store.dispatch("fetchData")
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'authToken'])
  },
  methods: {
    playAudio: function () {
      this.$refs.audio_main.play()
    },            
    changeLang: function (evt) {
      var value = evt.target.value
      if (evt != null && (value in this.langsModels)) {
        this.selectlang = value
        this.selectLangModels = this.langsModels[value].slice()
        this.selectModel = this.selectLangModels[0].value
        this.$refs.audio_main.src = this.selectLangModels[0].wavplay 
        this.$refs.audio_down.src = this.selectLangModels[0].wavplay 
      }   
    },
    changeModel: function (evt) {
      var value = evt.target.value
      if (this.selectLang in this.langsModels) {
        var selectLangModels = this.langsModels[this.selectLang].slice()
        for (var model of selectLangModels) {
          if (model.value === value) {
            this.$refs.audio_main.src = model.wavplay 
            this.$refs.audio_down.src = model.wavplay 
          }
        }  
      }   
    },
    wordCnt: function () {
      var value = this.voicetext
      if (value.length > this.text_maxlen) {
        var msg = this.$i18n.t('txt2voice.text_max_prompt1') + this.text_maxlen + this.$i18n.t('txt2voice.text_max_prompt2')
        top.alert(msg)
        this.voicetext = this.voicetext.substr(0, this.text_maxlen)
      } else {
        this.wordcnt = value.length
      }
    },
    genVoice: function () {
      var host = this.$i18n.t('host')
      var uri = host + '/api/genaudio'
      // var uri = '/api/genaudio'
      // console.log('uri:' + uri)
      // console.log('voicetext:' + this.voicetext)
      var model = this.selectModel.split('+')
      var modelcat = model[0]
      var modelname = model[1]
      if (!this.voicetext) {
        top.alert(this.$i18n.t('txt2voice.main_input_empty'))
        return false
      }
      this.disabled = true
      this.$refs.gen_button.innerHTML = this.$i18n.t('txt2voice.main_generating')
      // console.log('1btn value:' + this.$refs.gen_button.value)
      if (this.voicetext.length > this.text_maxlen) {
        this.voicetext = this.voicetext.substr(0, this.text_maxlen)
      }
      // userid: this.currentUser.email, // 使用用户邮箱作为 userid
      var formdata = {
        'modelcat': modelcat,
        'modelname': modelname,
        'text': this.voicetext,
        'subscript': this.user_subscript,
        'userid': 0,
        't': 1
      }
      var headers = {
        'Content-Type': 'application/json; charset=utf-8',
        // 'Authorization': `Bearer ${this.authToken}`,
      }
      var params = {
        headers: headers
      }
      var that = this
      axios.post(uri, formdata, params)
        .then(res => {
          // console.log(res)
          if (res.data.ret === 0) {
            var audioUrl = host + res.data.uri
            // var audioUrl = res.data.uri
            console.log('data_uri:' + res.data.uri)
            this.$refs.audio_main.src = audioUrl
            this.$refs.audio_main.play()
            this.$refs.audio_down.href = audioUrl
          } else {
            if (res.data.ret === 2) {
              // 达到当日使用限额
              // 未登录用户的弹窗
	      var popid = popup_nologin
              if (this.isLoggedIn) {
                // 已登录,但未订阅
                popid = popup_nosub
                if (this.user_subcript === 2 ) {
		  // 已订阅, 但字符已用完
		  popid = popup_sublimit
		}
	      }
	      this.openPopup(popid)
            } else {
              top.alert(res.data.msg)
            }
          }
        }).catch(error => {
          console.log(error)
          alert('An error occurred while generating the voice, please try again later!');
        }).finally(() => {
          setTimeout(() => {
            that.disabled = false
            that.$refs.gen_button.innerHTML = this.$i18n.t('txt2voice.main_genvoice')
          }, 2000)
        })
    },
    async getUserInfo (email) {
      if (!email) {
        console.log("email is empty, email:" + email)
	return false
      }
      try {
        const host = this.$i18n.t('host')
        const uri = `${host}/lapi/user/profile`
        const { data } = await axios.get(uri, {
          params: { email },
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })

        if (data.ret === 0 && data.user_info) {
          this.email = data.user_info.email
          this.user_subscript = data.user_info.user_subscript
        } else {
          console.log("ret:" + data.ret + ", msg:" + data.msg)
        }
      } catch (error) {
        console.error('Error fetching user information:', error)
        console.log('An error occurred while fetching user information, please try again later!')
      }
    },
    checkShowSideAds() {
      // <1024 中型屏幕设备 如pad, < 768 小型屏幕设备 如手机
      // 只有屏幕足够大才在两侧出广告, 手机上不出侧边栏广告
      if (window.innerWidth >= 900) {
        this.showSideAds = true
      } else {
        this.showSideAds = false
      } 
    },
    initializePopups() {
      for (const id in this.popupData) {
        // console.log('initializePopups 11 id:', id)
        this.popupStates[id] = false;
      }
    },
    openPopup(id) {
      // console.log('open popup 11 id:', id)
      this.popupStates[id] = true;
    },
    closePopup(id) {
      // console.log('close popup 11 id:', id)
      this.popupStates[id] = false;
      this.$forceUpdate();
    },
    loadCssAsync(href) {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        link.onload = () => resolve();
        link.onerror = () => reject();
        document.head.appendChild(link);
      });
    }
  },
  async created() {
    // 使用 Object.assign 一次性设置多个属性，减少响应式更新
    Object.assign(this, {
      langsModels: this.$store.state.langsModels,
      allLangs: this.$store.state.allLangs,
      selectLang: this.$store.state.selectLang,
      selectLangModels: this.$store.state.selectLangModels,
      selectModel: this.$store.state.selectModel,
      selectModelWavplay: this.$store.state.selectModelWavplay
    });

  },
  mounted () {
    // var lang = this.$store.state.urllang
    // const cookie = getCookie('locale');
    // 
    // if ((lang === null) || (lang === '')) {
    //   lang = cookie;
    // }
    // if ((lang === null) || (lang === '')) {
    //   lang = this.$store.state.lang
    // }
    // if ((lang === null) || (lang === '')) {
    //   lang = 'en'
    //   // console.log('33 mounted, lang from default:' + lang)
    // }
    // console.log('44 mounted, i18n locale:' + this.$i18n.locale)
    // const pathName = window.location.pathname;
    // if (pathName !== '/') {
    //   this.$i18n.locale = lang
    // } else {
    //   this.$i18n.locale = 'en'
    //   lang = 'en'  
    // }
    // console.log('55 mounted, i18n locale:' + this.$i18n.locale)

    this.pricing_href = '/' + this.$store.state.lang + '/pricing'    
    // console.log('home Before setting locale, current locale:', this.$i18n.locale)
    // loadLanguageAsync(this.$i18n, this.$store.state.lang)

    // console.log('home page 11, current user:', this.currentUser)

    const storedUserData = getCookie('userData');
    // console.log('home page 22, storedUserData:', storedUserData)
    if (storedUserData) {
      const userData = JSON.parse(storedUserData)
      // console.log('home page 33, user Data email:', userData.email)
      this.getUserInfo(userData.email)
    }

    this.$nextTick(() => {
      this.checkShowSideAds();
      this.debouncedCheckShowSideAds = debounce(this.checkShowSideAds, 250);
      window.addEventListener('resize', this.debouncedCheckShowSideAds, { passive: true });
      
      this.loadCssAsync('/ssr/css/popup_style.css').catch(error => {
        console.error('css popup_style load failed!, error: ', error);
      });

      // 使用 requestIdleCallback 延迟加载广告
      if ('requestIdleCallback' in window) {
        requestIdleCallback(lazyLoadAds);
      } else {
        setTimeout(lazyLoadAds, 1);
      }
    });

    this.initializePopups();
  },
  beforeUnmount() {
    if (this.debouncedCheckShowSideAds) {
      window.removeEventListener('resize', this.debouncedCheckShowSideAds);
    }
    window.removeEventListener('load', addBannerAds);
  }
}
</script>

<style>
</style>
